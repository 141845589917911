/** @jsx jsx */
import { useContext, useEffect } from 'react';
import { Router } from '@reach/router';
import Layout from '../layouts/Layout';
import { CustomerRoute } from '../components/account/CustomerRoute';
import { Profile } from '../components/account/Profile';
import { Default } from '../components/account/Default';
import { Column, Container, Row, Section } from '@nimles/react-web-components';
import { QuoteRequest } from '../components/account/quotes/QuoteRequest';
import {
  loadOrders,
  loadPublicCategories,
  loadPublicOrganization,
  loadPublicProductGroups,
  loadPublicProductProperties,
  loadQuoteRequests,
  loadQuotes,
  signOutUser,
} from '@nimles/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/types';
import { jsx } from '@emotion/react';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import { Navbar, NavbarButton, NavbarLink } from '../components/Navbar';
import { QuoteRequests } from '../components/account/quotes/QuoteRequests';
import {
  OrderModel,
  OrganizationModel,
  QuoteModel,
  QuoteRequestModel,
} from '@nimles/models';
import { graphql } from 'gatsby';
import { Orders } from '../components/account/orders/Orders';
import { Order } from '../components/account/orders/Order';
import styled from '@emotion/styled';

const AccountSection = styled(Section)`
  padding-top: ${({ theme }) => theme.header.height ?? 60}px;
`;

const Account = ({ location }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { language, routed } = useContext(I18nextContext);
  const accessToken = useSelector<RootState, string>(
    ({ auth }) => auth.accessToken
  );
  const publicOrganizations = useSelector<RootState, OrganizationModel[]>(
    ({ publicOrganizations }) => publicOrganizations.values
  );

  const quotes = useSelector<RootState, QuoteModel[]>(
    ({ quotes }) => quotes.values
  );
  const quoteRequests = useSelector<RootState, QuoteRequestModel[]>(
    ({ quoteRequests }) => quoteRequests.values
  );
  const orders = useSelector<RootState, OrderModel[]>(
    ({ orders }) => orders.values
  );

  useEffect(() => {
    dispatch(loadPublicCategories());
    dispatch(loadOrders());
    dispatch(loadQuotes());
    dispatch(loadQuoteRequests());
    dispatch(loadPublicProductGroups());
    dispatch(loadPublicProductProperties());
  }, [accessToken]);

  useEffect(() => {
    quotes
      .map(({ head }) => head.seller.organizationId)
      .filter(
        (organizationId) =>
          !publicOrganizations.some(({ id }) => organizationId === id)
      )
      .forEach((organizationId) =>
        dispatch(loadPublicOrganization(organizationId))
      );
  }, [quotes]);

  const handleSignOut = () => {
    dispatch(signOutUser());
  };

  const { pathname } = location;

  const basepath = routed ? `/${language}/account` : '/account';

  console.log('basepath', basepath);
  return (
    <Layout header footer headerAlwaysOpen location={location}>
      <AccountSection>
        <Navbar>
          <Container>
            <Row wrap="wrap">
              <Column>
                <NavbarLink
                  to="/account/profile"
                  isActive={pathname.indexOf('/account/profile') === 0}
                  activeClassName="active"
                >
                  {t('title.myProfile')}
                </NavbarLink>
              </Column>
              {quoteRequests.length ? (
                <Column>
                  <NavbarLink
                    to="/account/quoterequests"
                    isActive={pathname.indexOf('/account/quoterequests') === 0}
                    activeClassName="active"
                  >
                    {t('title.myQuoteRequests')}
                  </NavbarLink>
                </Column>
              ) : null}
              {orders.length ? (
                <Column>
                  <NavbarLink
                    to="/account/orders"
                    isActive={pathname.indexOf('/account/orders') === 0}
                    activeClassName="active"
                  >
                    {t('title.myOrders')}
                  </NavbarLink>
                </Column>
              ) : null}
              <Column flex></Column>
              <Column>
                <NavbarButton onClick={handleSignOut}>
                  {t('action.signOut')}
                </NavbarButton>
              </Column>
            </Row>
          </Container>
        </Navbar>
        <Container>
          <Router basepath={basepath}>
            <CustomerRoute path="/profile" component={Profile} />
            <CustomerRoute path="/quoterequests" component={QuoteRequests} />
            <CustomerRoute
              path="/quoterequests/:quoteRequestId"
              component={QuoteRequest}
            />
            <CustomerRoute path="/orders" component={Orders} />
            <CustomerRoute path="/orders/:orderId" component={Order} />
            <Default path="/" />
          </Router>
        </Container>
      </AccountSection>
    </Layout>
  );
};

export default Account;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
