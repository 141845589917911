import { updateCurrentUser } from '@nimles/react-redux';
import { UserModel } from '@nimles/models';
import { Card, CardBody } from '@nimles/react-web-components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/types';
import { UserForm } from '../forms/user/UserForm';

export const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector<RootState, UserModel>(
    ({ currentUser }) => currentUser.user
  );

  const handleSubmit = (values: any) => {
    dispatch(updateCurrentUser({ ...user, ...values }));
  };

  return (
    <Card>
      <CardBody>
        {user.firstName}
        <UserForm user={user} onSubmit={handleSubmit} />
      </CardBody>
    </Card>
  );
};
