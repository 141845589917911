import React from 'react';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/types';

export const CustomerRoute = ({ component: Component, ...props }) => {
  const accessToken = useSelector<RootState, string>(
    ({ auth }) => auth.accessToken
  );

  if (!accessToken) {
    navigate('/login');
    return null;
  }

  return <Component {...props} />;
};
