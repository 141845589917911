import React, { FC } from 'react';
import { Formik, FormikHelpers, FormikValues, FormikProps } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Row, Column, Button } from '@nimles/react-web-components';
import { InputField } from '@nimles/react-web-forms';
import { UserModel } from '@nimles/models';

interface Props {
  user?: UserModel;
  onSubmit: (x: any) => void;
}

export const UserForm: FC<Props> = ({ user, onSubmit }) => {
  const values = {
    username: user && user.username,
    id: user && user.id,
    firstName: user && user.firstName,
    lastName: user && user.lastName,
    phoneNumber: (user && user.phoneNumber) || '',
    address: {
      street: (user && user.address && user.address.street) || '',
      postalCode: (user && user.address && user.address.postalCode) || '',
      city: (user && user.address && user.address.city) || '',
    },
  };

  const { t } = useTranslation();

  const handleSubmit = (
    values: FormikValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<FormikValues>
  ) => {
    try {
      onSubmit(values);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
      }: FormikProps<FormikValues>) => (
        <form
          onSubmit={handleSubmit}
          noValidate
          name="simpleForm"
          className="user-form"
        >
          <Row wrap="wrap" className="user-form__name">
            <Column xs={100} sm={50}>
              <InputField name="firstName" label={t('prop.firstName')} />
            </Column>
            <Column xs={100} sm={50}>
              <InputField name="lastName" label={t('prop.lastName')} />
            </Column>
          </Row>
          <Row>
            <Column xs={100}>
              <InputField name="phoneNumber" label={t('prop.phoneNumber')} />
            </Column>
          </Row>
          <Row>
            <Column xs={100}>
              <InputField name="address.street" label={t('prop.street')} />
            </Column>
          </Row>
          <Row wrap="wrap">
            <Column xs={100} sm={50}>
              <InputField
                name="address.postalCode"
                label={t('prop.postalCode')}
              />
            </Column>
            <Column xs={100} sm={50}>
              <InputField name="address.city" label={t('prop.city')} />
            </Column>
          </Row>
          <Row justify="flex-end" margin="10px -15px 0">
            <Column>
              <Button raised primary type="submit">
                {t(isSubmitting ? 'action.saving' : 'action.save')}
              </Button>
            </Column>
          </Row>
        </form>
      )}
    />
  );
};
